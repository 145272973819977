:root {
  --color-green-1: 224,239,221;
  --color-green-2: 186,217,173;
  --color-green-3: 166,206,149;
  --color-green-4: 53,121,75;
  --color-green-5: 35,66,20;
  --color-red: 224,58,62;

  --body-margin: 1rem;
  --content-spacing: 1rem;
  --icon-button-size: 1.2em;

  --container-width: 720px;

  --source-link-dimension: 3rem;
  --source-link-position: calc(1.5 * var(--body-margin));
}

html {
  font-family: 'Lato', sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body:not([data-environment="production"])::before {
  content: attr(data-environment);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  line-height: 1.5em;
  font-size:  1.5rem;
  text-align: center;
  text-transform: uppercase;
  background: #000;
  color: #fff;
  opacity: 0.2;
}

header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header svg {
  max-width: calc(100vw - 2em);
  width: 665px;
}

p {
  margin: 0;
}

p + p {
  margin-top: 1em;
}

input {
  padding: 0.5em;
  border: 1px solid rgb(var(--color-green-2));
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
}

input[type="checkbox"] {
  position: relative;
  width: 0;
  height: 1em;
  margin-right: 1em;
  vertical-align: top;
}

input[type="checkbox"]:before {
  --size: 1em;

  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: #fff;
  border: calc(var(--size) / 2) solid #fff;
  background: rgb(var(--color-green-4));
  transition: border-width 0.2s;
}

input[type="checkbox"]:checked:before {
  border-width: 3px;
}

button {
  --border-width: calc(1em / 8);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(0.5em - 2 * var(--border-width)) calc(1em - 2 * var(--border-width));
  border: none;
  background: rgb(var(--color-green-2));
  border: 2px solid rgb(var(--color-green-4));
  border-radius: 0.25em;
  white-space: nowrap;
  cursor: pointer;
}

button[type="submit"] {
  background: rgb(var(--color-green-4));
  color: rgb(var(--color-green-1));
  font-weight: bold;
}

button:global(.icon) {
  border: 0 none;
  padding: 0;
  border-radius: 50%;
  width: var(--icon-button-size);
  height: var(--icon-button-size);
}

:global(.source-link) {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: var(--source-link-position);
  right: var(--source-link-position);
  min-width: var(--source-link-dimension);
  height: var(--source-link-dimension);
  line-height: var(--source-link-dimension);
  padding: 0 1ex;
  border-radius: calc(var(--source-link-dimension) / 2);
  background-color: rgba(var(--color-green-1), 0.9);
  box-shadow: 0 0 5px rgba(var(--color-green-3), 0.9);
  color: rgb(var(--color-green-5));
}

:global(.source-link) svg {
  display: inline-block;
  height: 1.5em;
  width: auto;
}

:global(.source-link) span {
  display: inline-block;
  max-width: 0;
  height: var(--source-link-dimension);
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 0.2s;
}

:global(.source-link):hover span,
:global(.source-link):focus span {
  max-width: 20em;
  margin-left: 0.5ex;
}

:global(.release) {
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  color: #999;
}

:global(#root) {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--body-margin);
  width: calc(var(--container-width) - var(--body-margin));
}

:global(#root) > * + * {
  margin-top: var(--content-spacing);
}

@media only screen and (max-width: 720px) {
  :global(#root) {
    width: calc(100vw - 2em);
    margin-bottom: calc(2 * var(--source-link-position) + var(--source-link-dimension));
  }
}
