.container {
  display: flex;
  margin: 0 1em;
}

.container > input {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 8em;
  margin: 0 0.5em 0 0;
}

@media only screen and (max-width: 480px) {
  .container {
    width: calc(100vw - 2em);
    flex-direction: column;
  }

  .container > input {
    margin: 0 0 0.5em 0;
  }
}
